import React from "react";
import { StaticImage } from "gatsby-plugin-image";

interface Props {
  title?: string;
  logo?: boolean;
  children?: React.ReactNode;
}

export default function FullscreenImage({ title, logo, children }: Props) {
  return (
    <div className="ic">
      <div className="ic__overlay" />
      {logo ? (
        <div className="ic__logo">
          <StaticImage alt="Catering tillsammans logotyp" layout="constrained" placeholder="none" src="../images/logo/30_logo_w_bg2.png" />
        </div>
      ) : (
        <div className="ic__text">
          <h1 className="ic__title">{title}</h1>
          <p className="ic__description">{children}</p>
        </div>
      )}
      <StaticImage
        alt={logo ? "Rotselleri" : "Blandade grönsaker och rotfrukter såsom potatis, rotselleri och kål "}
        className={`ic__img ${logo ? "ic__img--logo" : ""}`}
        layout="fullWidth"
        placeholder="blurred"
        src="../images/food/e8_widescreen.jpg"
        style={{ position: "unset" }}
      />
    </div>
  );
}
