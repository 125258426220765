import React from "react";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import FullscreenImage from "../components/fullscreen-image";
import Layout from "../components/layout";
import SEOTags from "../components/seo-tags";
import { GraphQLData } from "../interfaces/page-template";

// eslint-disable-next-line react/function-component-definition
const Page = (props: PageProps<GraphQLData>) => {
  const { data } = props;
  const StaticPage = data.wpPage;
  const imageData = StaticPage.featuredImage?.node.localFile ? getImage(StaticPage.featuredImage.node.localFile) : undefined;

  return (
    <Layout>
      <SEOTags description={StaticPage.seo.metaDesc ?? StaticPage.title} title={StaticPage.title} />
      {data.wpPage.slug !== "hem" && StaticPage.featuredImage && (
        <div className="ic">
          <div className="ic__overlay" />
          <div className="ic__text">
            <h1 className="ic__title">{StaticPage.title}</h1>
          </div>
          <div className="ic__img">
            {imageData && <GatsbyImage alt={StaticPage.featuredImage.node.altText} image={imageData} style={{ position: "unset" }} />}
          </div>
        </div>
      )}
      {data.wpPage.slug === "hem" && <FullscreenImage logo />}
      <div className="wp-page">
        <div className="container">
          <span dangerouslySetInnerHTML={{ __html: StaticPage.content }} />
        </div>
      </div>
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  fragment HeroImage on File {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH, quality: 100, placeholder: BLURRED)
    }
  }
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      slug
      seo {
        metaDesc
        metaKeywords
        title
      }
      featuredImage {
        node {
          altText
          localFile {
            ...HeroImage
            publicURL
          }
          mediaDetails {
            width
            height
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
  }
`;
